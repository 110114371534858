export const environment = {
  production: true,
  noDisplayValue: 'No Especificar',
  baseMultimediaUrl: 'https://cdn.verificadas.cl',
  baseUrl: 'https://api.verificadas.cl',
  baseImageUrl: 'https://cdn.verificadas.cl/images',
  basePendingimageUrl: 'https://cdn.verificadas.cl/pending-images',
  baseAccessUrl: 'https://verificadas.cl',
};

// export const environment = {
//   production: false,
//   noDisplayValue: 'No Especificar',
//   baseUrl: 'http://localhost:3000',
//   baseMultimediaUrl: 'http://localhost:3100',
//   baseImageUrl: 'http://localhost:3100/images',
//   basePendingimageUrl: 'http://localhost:3100/pending-images',
//   baseAccessUrl: 'http://localhost:4200',
// };
