<div class="surface-section px-4 py-8 md:px-6 lg:px-8" *ngIf="blog; else noBlog">
  <div class="text-600 text-sm mb-3">
    <span>Publicado el</span>
    <span class="font-bold pl-1">{{ blog.createdAt | date : "dd/MM/yyyy" }}</span>
    <span> por </span>
    <span class="font-bold">Verificadas.cl</span>
  </div>
  <h1 class="border-blue-600 font-medium text-2xl p-3 mb-3 text-900" style="border-left: 8px solid">
    {{ blog.title }}
  </h1>
  <div class="line-height-3 text-xl text-700 mb-5">
    {{ blog.shortDescription }}
  </div>
  <!-- <div class="mb-5">
        <p-tag value="Escorts" class="mr-2"></p-tag>
        <p-tag value="Santiago Oriente" severity="success" class="mr-2"></p-tag>
        <p-tag value="Premium" severity="warning"></p-tag>
      </div> -->
  <div [innerHTML]="blog.content"></div>
</div>
<ng-template #noBlog>
  <div class="surface-section px-4 py-8 md:px-6 lg:px-8">
    <h1 class="border-blue-600 font-medium text-2xl p-3 mb-3 text-900" style="border-left: 8px solid">Lo sentimos</h1>
    <div class="line-height-3 text-xl text-700 mb-5">El articulo que esta buscando no existe</div>
  </div>
</ng-template>
