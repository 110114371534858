<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
  <div style="background: radial-gradient(50% 109137.91% at 50% 50%, rgba(233, 30, 99, 0.1) 0%, rgba(254, 244, 247, 0) 100%)" class="text-center">
    <span class="bg-white text-pink-500 font-bold text-2xl inline-block px-3">404</span>
  </div>
  <div class="mt-6 mb-5 font-bold text-6xl text-900 text-center">Página No Encontrada</div>
  <p class="text-700 text-3xl mt-0 mb-6 text-center">
    Lo sentimos, la página que estás buscando no existe. Puede que haya sido eliminada, su nombre cambiado o esté temporalmente no disponible. Por favor, vuelve
    a la página de inicio o utiliza la barra de búsqueda para encontrar lo que necesitas.
  </p>
  <div class="text-center">
    <a href="https://verificadas.cl/escorts/Santiago">
      <button type="button" pButton pRipple label="Ir a inicio" icon="pi pi-home"></button>
    </a>
  </div>
</div>
