<div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
  <div class="text-900 font-bold text-6xl mb-4 text-center">Precios</div>
  <div class="text-700 text-xl mb-6 text-center line-height-3">Encuentra el plan perfecto para ti!</div>

  <div class="grid">
    <ng-container>
      <div class="col-12 lg:col-4">
        <div class="p-3 h-full">
          <div class="shadow-2 p-3 flex flex-column surface-card" style="border-radius: 6px">
            <div class="text-900 font-medium text-xl mb-2">Plan Premium</div>
            <div class="text-600">Verificadas CL sera gratuito hasta nuevo aviso!</div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <ng-container>
                <span class="font-bold text-2xl text-900 line-through"> $ XXX.XXX </span>
                <i class="pi pi-arrow-right px-2"></i>
                <span class="font-bold text-2xl text-900 text-primary-500">
                  $ Gratis!
                </span>
              </ng-container>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">Accesso ilimitado a nuestra plataforma</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">3 Congelaciónes</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">16 Fotos</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">3 Videos</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">atención preferencial</span>
              </li>
            </ul>
            <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
            <button pButton pRipple label="Anunciate!" class="p-3 w-full" (click)="goToAnounce('Premium')"></button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- <div class="grid">
    <ng-container *ngIf="economicPricingPlan">
      <div class="col-12 lg:col-4">
        <div class="p-3 h-full">
          <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
            <div class="text-900 font-medium text-xl mb-2">Plan Economico</div>
            <div class="text-600">Para períodos de tiempo menores a un mes, contáctese con nosotros.</div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <ng-container *ngIf="economicPricingPlan.discount === 0">
                <span class="font-bold text-2xl text-900">{{ formatPrice(economicPricingPlan.price) }}</span>
                <span class="ml-2 font-medium text-600">Mensual</span>
              </ng-container>
              <ng-container *ngIf="economicPricingPlan.discount !== 0">
                <span class="font-bold text-2xl text-900 line-through">
                  {{ formatPrice(economicPricingPlan.price) }}
                </span>
                <i class="pi pi-arrow-right px-2"></i>
                <span class="font-bold text-2xl text-900 text-primary-500">
                  {{ getFormatedDiscountedPrice(economicPricingPlan) }}
                </span>
                <span class="ml-2 font-medium text-600">Mensual</span>
              </ng-container>
            </div>
            <span class="ml-2 mt-1 font-medium text-600" *ngIf="economicPricingPlan.discountMessage !== ''">{{ economicPricingPlan.discountMessage }}</span>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">Accesso ilimitado a nuestra plataforma</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">1 Congelación</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">8 Fotos</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">1 Video</span>
              </li>
            </ul>
            <hr class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto" />
            <button pButton pRipple label="Anunciate!" class="p-3 w-full mt-auto" (click)="goToAnounce('Economico')"></button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="regularPricingPlan">
      <div class="col-12 lg:col-4">
        <div class="p-3 h-full">
          <div class="shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
            <div class="text-900 font-medium text-xl mb-2">Plan Intermedio</div>
            <div class="text-600">Para períodos de tiempo menores a un mes, contáctese con nosotros.</div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <ng-container *ngIf="regularPricingPlan.discount === 0">
                <span class="font-bold text-2xl text-900">{{ formatPrice(regularPricingPlan.price) }}</span>
                <span class="ml-2 font-medium text-600">Mensual</span>
              </ng-container>
              <ng-container *ngIf="regularPricingPlan.discount !== 0">
                <span class="font-bold text-2xl text-900 line-through">
                  {{ formatPrice(regularPricingPlan.price) }}
                </span>
                <i class="pi pi-arrow-right px-2"></i>
                <span class="font-bold text-2xl text-900 text-primary-500">
                  {{ getFormatedDiscountedPrice(regularPricingPlan) }}
                </span>
                <span class="ml-2 font-medium text-600">Mensual</span>
              </ng-container>
            </div>
            <span class="ml-2 mt-1 font-medium text-600" *ngIf="regularPricingPlan.discountMessage !== ''">{{ regularPricingPlan.discountMessage }}</span>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">Accesso ilimitado a nuestra plataforma</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">2 Congelaciónes</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">12 Fotos</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">2 Videos</span>
              </li>
            </ul>
            <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
            <button pButton pRipple label="Anunciate!" class="p-3 w-full" (click)="goToAnounce('Intermedio')"></button>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="premiumPricingPlan">
      <div class="col-12 lg:col-4">
        <div class="p-3 h-full">
          <div class="shadow-2 p-3 flex flex-column surface-card" style="border-radius: 6px">
            <div class="text-900 font-medium text-xl mb-2">Plan Premium</div>
            <div class="text-600">Para períodos de tiempo menores a un mes, contáctese con nosotros.</div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <ng-container *ngIf="premiumPricingPlan.discount === 0">
                <span class="font-bold text-2xl text-900">{{ formatPrice(premiumPricingPlan.price) }}</span>
                <span class="ml-2 font-medium text-600">Mensual</span>
              </ng-container>
              <ng-container *ngIf="premiumPricingPlan.discount !== 0">
                <span class="font-bold text-2xl text-900 line-through">
                  {{ formatPrice(premiumPricingPlan.price) }}
                </span>
                <i class="pi pi-arrow-right px-2"></i>
                <span class="font-bold text-2xl text-900 text-primary-500">
                  {{ getFormatedDiscountedPrice(premiumPricingPlan) }}
                </span>
                <span class="ml-2 font-medium text-600">Mensual</span>
              </ng-container>
            </div>
            <span class="ml-2 mt-1 font-medium text-600" *ngIf="premiumPricingPlan.discountMessage !== ''">{{ premiumPricingPlan.discountMessage }}</span>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">Accesso ilimitado a nuestra plataforma</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">3 Congelaciónes</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">16 Fotos</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">3 Videos</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span class="text-900">atención preferencial</span>
              </li>
            </ul>
            <hr class="mb-3 mx-0 border-top-1 border-none surface-border" />
            <button pButton pRipple label="Anunciate!" class="p-3 w-full" (click)="goToAnounce('Premium')"></button>
          </div>
        </div>
      </div>
    </ng-container>
  </div> -->
</div>
