<ng-container *ngIf="allGirls.length !== 0; else noGirlsTemplate">
  <div>
    <div class="flex-column">
      <h1 class="text-center">{{ title }}</h1>
      <p class="text-center w-full px-4">{{ description }}</p>
    </div>
    <p-divider class="w-full"></p-divider>
    <app-filter></app-filter>
    <p-divider class="w-full"></p-divider>
    <app-product-grid></app-product-grid>
  </div>
</ng-container>
<ng-template #noGirlsTemplate>
  <div class="no-girls-message flex-column justify-center">
    <h1 class="text-center">Lo sentimos</h1>
    <p class="text-center w-full">No hemos encontrado escorts que cumplan con tu criterio de busqueda.</p>
    <a class="w-full flex justify-content-center pb-3 no-underline" [href]="getThrowBackLink()">
      <button
        pButton
        pRipple
        icon="pi pi-arrow-left"
        label="Volver a todas las escorts"
        class="justify-center align-items-center"
      ></button>
    </a>
  </div>
</ng-template>
