<div class="surface-ground px-4 py-8 md:px-6 lg:px-8">
  <div class="font-bold text-5xl text-900 mb-5 text-center">Blog Sobre Escorts Premium</div>
  <div class="grid nogutter">
    <div class="col-12 lg:col-4 p-3 no-underline" *ngFor="let blog of this.blogs">
      <a class="no-underline" [href]="'https://verificadas.cl/blog/' + blog.id">
        <div class="shadow-2 border-round h-full surface-card cursor-pointer">
          <div class="p-4">
            <span class="block font-medium text-blue-600 mb-3">{{ blog.category }}</span>
            <div class="text-xl text-900 font-medium mb-3 line-height-3">
              {{ blog.title }}
            </div>
            <div class="line-height-3 mb-3 text-700">
              {{ blog.shortDescription }}
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
